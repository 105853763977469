import * as Sentry from "@sentry/sveltekit";
import { version } from "$app/environment";

Sentry.init({
  dsn: "https://6c29c372ba6645d18c960d1f2cd2a3b1@o1352253.ingest.sentry.io/6647566",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    })
  ],
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE === 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  release: version
});

/** @type {import('@sveltejs/kit').HandleClientError} */
export function handleError({ error, event, status }) {
  const errorId = crypto.randomUUID();
  Sentry.captureException(error, {
		extra: { event, errorId, status },
	});
  
  return {
    message: 'Whoops!',
    code: error.code ?? 'UNKNOWN',
    stack: error.stack,
    errorId
  };
}
